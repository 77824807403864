$errorColor: #f1556c;
$disabledColor: #e9ecef;

.input-field.has-error {
  border-color: $errorColor;
}

.cursor-pointer {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.dropdown-toggle-split {
  &:after {
    display: inline-block;
    /*margin-left: .255em;*/
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    margin-left: 0;
  }
}

.enlarged .left-side-menu {
  width: $leftbar-width-collapsed !important;
}

.form-group[required] {
  & > label:after {
    content: "*";
    color: $errorColor;
  }
  .label:after {
    content: "*";
    color: $errorColor;
  }
}

.b-tooltip.tooltip-white {
  opacity: 1;

  &[x-placement="right"] {
    .arrow {
      &::before {
        border-right-color: #fff;
      }
    }
  }

  &[x-placement="left"] {
    .arrow {
      &::before {
        border-left-color: #fff;
      }
    }
  }

  &[x-placement="top"] {
    .arrow {
      &::before {
        border-top-color: #fff;
      }
    }
  }

  &[x-placement="bottom"] {
    .arrow {
      &::before {
        border-bottom-color: #fff;
      }
    }
  }

  .tooltip-inner {
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 0.50rem rgba(0, 0, 0, 0.2);
    max-width: 400px;
  }
}

.payment-system-logo {
  height: 18px;
}

.disabled-input {
  .form-control {
    background-color: $disabledColor;
    opacity: 1;
  }
}

.form-control {
  &:disabled {
    background-color: $disabledColor;
    opacity: 1;
  }
}

.content-page {
  margin-top: 0;
}

.left-side-menu {
  top: 0;
}

div.v--modal-overlay {
  background: rgba(20, 20, 47, .6);
}

div.v--modal,
.modal-content {
  color: var(--modal-content-color);
  background-color: var(--modal-content-bg);
}

.modal-header {
  justify-content: flex-start;
  align-items: center;

  &__submit {
    margin-left: auto;
  }

  &__cancel {
    font-size: 18px;
    padding: 4px 10px;
    margin: 0 10px 0 -10px;
  }
}

.dialog-c-text {
  overflow: auto;
}

body pre {
  color: var(--body-color);
}

.vue-dialog {
  div.dialog-c-title {
    font-size: 0.9375rem;
    margin: 10px 0;
    padding: 0;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    color: var(--headings-color);
    text-align: center;
  }

  .dialog-c-text {
    max-height: calc(80vh);
  }
}

.circle-icon {
  height: 22px;
  width: 22px;
  border: 1px var(--icon-color) solid;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-group .input-group-text {
  background-color: $primary;
  border-color: $primary;
  color: $white;

  &:hover {
    color: #fff;
    background-color: #4938d7;
    border-color: #3f2ed4;
  }
}

div.tui-editor-defaultUI {
  color: var(--input-color);
  border: 1px solid var(--input-border);
  border-radius: 5px;
}

div.te-toolbar-section {
  border: none;
}

div.tui-editor-defaultUI-toolbar {
  background-color: var(--input-bg);
  border: none;
  border-bottom: 1px solid var(--input-border);

  button {
    border-radius: 5px;
  }
}

div.tui-editor {
  color: var(--input-color);
}

div.tui-editor-contents p {
  color: var(--input-color);
}

div.te-ww-container {
  border: none;
  background-color: var(--input-bg);
}

div.tui-toolbar-divider {
  background: var(--input-border);
}

div.tui-popup-wrapper {
  background-color: var(--input-bg);
  border-bottom: 1px solid var(--input-border);
}

div.b-toast.b-toast-solid .toast,
div.toast {
  background: var(--modal-content-bg);
  color: var(--input-color);
  box-shadow: $shadow-dark;

  button.close {
    color: var(--input-color);
  }
}

.toast-header {
  background: var(--input-bg);
  border-bottom: 1px solid var(--input-border);
}

.chart {
  &__content {
    height: 194px;
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 16px;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 160px;
      justify-content: center;
    }
  }

  &__legend {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 12px;
    padding: 0 5px;
    color: #7F869B;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }

    &._top {
      font-weight: 500;
      border-bottom: 0.5px solid rgba(127, 134, 155, .5);
      padding: 0 0 4px 20px;
    }
  }

  &__type {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 0.75rem;
  }

  &__text {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  &__text-value {}

  &__text-percent {
    margin-left: 5px;
    width: 54px;
  }

  &__color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.comment-count {
  background-color: var(--light);
  border-radius: 5px;
  width: fit-content;
  padding: 2px;
  font-size: 12px;
  position: relative;
  min-width: 30px;
  text-align: center;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4px solid var(--light);
    border-right: 4px solid transparent;
    border-top: 4px solid var(--light);
    border-bottom: 4px solid transparent;
    left: 6px;
    bottom: -8px;
  }

  &.filled {
    background-color: rgba(#9bc7fc, .9);
    color: #fff;
    &:before {
      border-left-color: rgba(#9bc7fc, .9);
      border-top-color: rgba(#9bc7fc, .9);
    }
  }
}
