

















































.slimscroll-menu {
  height: 100%;
}

.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}

.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}

.left-side-menu {
  .nprogress-busy &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-width: 767.98px) {
  .left-side-menu {
    padding: 0;
  }
}
