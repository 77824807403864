.grid-table {
  overflow-x: auto;
  //min-height: 340px;

  [class*="table-responsive-"] {
    margin-bottom: 0;
  }

  &__footer {
    overflow: hidden;
  }

  &.row-clickable {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  table {
    font-weight: 400;
    font-size: 13px;
    border-spacing: 0 15px;
    border-collapse: separate;

    tr {
      background-color: var(--table-tr-bg);
      box-shadow: 0 3px 9px 0 var(--table-tr-shadow);
      &.b-table-details {
        .grid-table {
          table {
            border-spacing: 0;
          }
        }
      }
    }

    thead {
      &.hidden-header {
        display: none;
      }

      th {
        vertical-align: middle;

        &:first-child {
          padding-right: 0;
        }

        &.b-table-sort-icon-left {
          background-image: none !important;
          padding-left: 0.85rem !important;
        }

        .sortable {
          border-bottom: 1px dashed #cecece;
          padding: 3px 0;
        }

        .refresh-btn {
          cursor: pointer;
        }

        &:not(:first-child) {
          .refresh-btn {
            display: none;
          }
        }
      }
    }

    tbody {
      tr {
        &.b-table-details {
          background-color: var(--table-detail);
          &:hover {
            background-color: var(--table-detail);
          }

          td {
            //white-space: unset;
            padding: 0;

            .detail-block {
              box-shadow: 0 3px 9px 0 var(--table-tr-shadow);
              &:before, &:after {
                content: '';
                display: block;
                width: 100%;
                height: 7.5px;
                background-color: var(--body-bg);
              }
              &:first-child {
                &:before {
                  display: none;
                }
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
              .page-title, th, td {
                color: #6c757d !important;
              }
            }

            .page-title-widget {
              background-color: var(--table-detail);
              padding: 0 0.85rem;
              .page-title {
                font-size: 0.9375rem !important;
              }
            }

            .table {
              tr {
                background-color: var(--table-detail);
                td {
                  padding: 0.85rem;
                  //&:first-child {
                  //  padding-left: 0.85rem;
                  //}
                  //&:last-child {
                  //  padding-right: 0.85rem;
                  //}
                }
              }
            }
          }
        }

        td {
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.no-wrap-disable {
            white-space: unset !important;
            max-width: 0;
            width: 100%;
          }

          &.cell-visible {
            overflow: visible;
          }

          &.action {
            overflow: visible;
          }
        }

        &:hover {
          background-color: var(--table-tr-bg);
        }
      }
    }
  }

  .b-pagination {
    li[role="separator"] {
      .page-link {
        border: none;
        background-color: #f5f6f8;
      }
    }
  }

  .hidden {
    display: none;
  }

  .pagi-left-block {
    @media screen and (max-width: 768px) {
      margin-bottom: 0.75rem;
    }
  }

  .current-page-input {
    width: 82px;
  }
}
