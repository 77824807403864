// 
// tables.scss
//


//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    color: var(--table-color);
    background-color: var(--table-bg);
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: var(--table-hover-bg);
            }
        }

        .table-active {
            &,
            > th,
            > td {
                color: var(--table-active-color);
                border-top: 1px var(--table-active-border) solid;
                border-bottom: 1px var(--table-active-border) solid;
                &:first-child {
                    border-left: 1px var(--table-active-border) solid;
                }
                &:last-child {
                    border-right: 1px var(--table-active-border) solid;
                }
            }
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}